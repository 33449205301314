import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ title, description, image, siteUrl }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            lang
            image
            description
            siteUrl
            twitterName
          }
        }
      }
    `
  )

  const defaults = site.siteMetadata

  //ページ内に設定がなければ、gatsby-config.jsで設定したものを出力
  const seo = {
    title: title || defaults.title,
    description: description || defaults.description,
    image: `${defaults.siteUrl}${image || defaults.image}`,
    url: `${defaults.siteUrl}${pathname}` || "",
  }

  return (
    <Helmet title={seo.title}>
      <html lang={defaults.lang} />
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {/* OGP */}
      <meta property="og:url" content={seo.url} />
      <meta property="og:site_name" content={seo.title} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={defaults.twitterName} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  )
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  lang: null,
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
}

export default SEO
